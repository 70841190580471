import React from 'react';
import styled from 'styled-components';

const ContactForm = styled.div`
  height: auto;

  & p {
    margin: 0;
    padding: 18px;
    width: 100%;

    border: 2px solid #d27dff;
    background-color: #fff;

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
  }
  & p:nth-child(2) {
    border-bottom: 0;
  }
  & p:nth-child(3) {
    border-bottom: 0;
  }
  & p:nth-child(5) {
    padding: 0;
    margin-top: 17px;

    border: 0;
    background-color: #dcbeff;
  }
  & label {
    color: #d27dff;
  }
  & input,
  textarea {
    margin-left: 18px;
    width: calc(100% - 65px - 18px);
    border: 0;
    -webkit-appearance: none;
    padding: 0;
    &:focus {
      outline: none;
    }
  }

  & input {
    height: 22px;
    position: relative;
    line-height: 1;
  }
  & button {
    display: flex;
    justify-content: space-between;

    padding: 17px;
    margin: 0;

    width: 100%;

    border: 0;
    -webkit-appearance: none;

    background-color: #000;
    color: #fff;

    cursor: pointer;
  }
`;

const ContactHeader = styled.div`
  background-color: #d27dff;

  color: #fff;
  text-align: center;
  font-size: 20px;

  padding: 17px;
`;

const Arrow = styled.span`
  line-height: 14px;
`;

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

class NetlifyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: '',
      email: '',
      message: '',
      formMessage: 'SEND MESSAGE',
    };
  }

  /* Here’s the juicy bit for posting the form submission */

  handleChangeNetlifyForm = e =>
    this.setState({ [e.target.name]: e.target.value });

  handleSubmitNetlifyForm = e => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state }),
    })
      .then(() => {
        this.setState({
          formMessage: `THANKS FOR CONTACTING US`,
          subject: '',
          email: '',
          message: '',
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          formMessage: `ERROR`,
        });
      });

    e.preventDefault();
  };

  render() {
    const { subject, email, message } = this.state;

    return (
      <ContactForm>
        <ContactHeader>CONTACT US</ContactHeader>
        <form onSubmit={this.handleSubmitNetlifyForm}>
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <p>
            <label>Subject</label>
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              value={subject}
              onChange={this.handleChangeNetlifyForm}
              required
            />
          </p>
          <p>
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              required
              value={email}
              onChange={this.handleChangeNetlifyForm}
            />
          </p>
          <p>
            <label>Message</label>
            <textarea
              name="message"
              rows="7"
              placeholder="Message"
              value={message}
              onChange={this.handleChangeNetlifyForm}
              required
            />
          </p>
          <p>
            <button type="submit">
              {this.state.formMessage} <Arrow>→</Arrow>
            </button>
          </p>
        </form>
      </ContactForm>
    );
  }
}

export default NetlifyForm;
