import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import { Row, Col } from 'styled-bootstrap-grid';
import ContentContainer from '../components/global/contentContainer';

import Title from '../components/text/title';
import Alert from '../components/text/alert';
import { Line } from '../components/utility/line';

import styled from 'styled-components';
import GoogleMap from '../components/utility/googleMap';

import MailchimpForm from '../components/contact/MailchimpForm';
import NetlifyForm from '../components/contact/NetlifyForm';

const Theme = {
  highlightColor: '#d27dff',
  textColor: '#000000',
};

const ContainerTheme = {
  backgroundColor: '#dcbeff',
};

const ContactColumn = styled.div`
  font-size: 17px;

  & p:first-child {
    margin-top: 0;
  }

  &:last-of-type {
    margin: 0 0 40px 0;
  }

  & a {
    color: inherit;
  }
`;

const Address = styled.h2`
  font-size: 20px;

  margin-bottom: 54px;
`;

class Contact extends React.Component {
  render(props) {
    return (
      <>
        <Helmet>
          <title>{this.props.data.prismicContact.data.title.text}</title>
          <meta
            name="title"
            content={this.props.data.prismicContact.data.title.text}
          />
          <meta
            name="description"
            content={this.props.data.prismicContact.data.text.text}
          />
          <meta
            property="og:url"
            content={
              'https://www.draw-brighton.co.uk' + this.props.location.pathname
            }
          />
          <meta
            property="og:description"
            content={this.props.data.prismicContact.data.text.text}
          />
          <meta property="og:locale" content="en" />
          <meta
            name="twitter:title"
            content={this.props.data.prismicContact.data.title.text}
          />
          <meta
            name="twitter:description"
            content={this.props.data.prismicContact.data.text.text}
          />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <Row css={ContainerTheme} justifyContent="center">
          <Col col={12}>
            <ContentContainer padding={'0 36px'}>
              <Title
                margin={'80px 0 36px 0'}
                text={this.props.data.prismicContact.data.title.text}
                case="inherit"
              />
            </ContentContainer>
          </Col>

          <Col col={12}>
            <ContentContainer padding={'0 36px'} mobilePadding={'0 9px'}>
              <Alert
                margin={'0 0 36px 0'}
                text={this.props.data.prismicContact.data.text.html}
                case="inherit"
              />
            </ContentContainer>
          </Col>

          <Col col={12}>
            <Line
              margin={'0 36px 54px 36px'}
              mobileMargin={'0 9px'}
              color={Theme.highlightColor}
            />
          </Col>

          <Col col={12}>
            <ContentContainer padding={'0 36px'} mobilePadding={'40px 9px 0'}>
              <Row>
                <Col col={12} md={3}>
                  <ContactColumn
                    dangerouslySetInnerHTML={{
                      __html: this.props.data.prismicContact.data
                        .get_in_touch_text.html,
                    }}
                  />
                </Col>

                <Col col={12} md={6}>
                  <NetlifyForm />

                  <MailchimpForm
                    signupText={
                      this.props.data.prismicContact.data.mailchimp_signup_text
                    }
                  />
                </Col>

                <Col col={12} md={3}>
                  <ContactColumn
                    dangerouslySetInnerHTML={{
                      __html: this.props.data.prismicContact.data
                        .social_media_text.html,
                    }}
                  />
                </Col>
              </Row>
            </ContentContainer>
          </Col>

          <Col col={12}>
            <Line
              margin={'75px 36px 30px 36px'}
              mobileMargin={'0 9px'}
              color={Theme.highlightColor}
            />
          </Col>

          {/* <Col col={12}>
            <ContentContainer padding={'0 36px'}>
              <Address>
                {this.props.data.prismicContact.data.main_studio_text}
              </Address>
            </ContentContainer>
          </Col> */}

          <Col col={12}>
            <ContentContainer
              padding={'28px 36px 54px'}
              mobilePadding={'54px 9px'}
            >
              <Row>
                <Col col={12} md={4}>
                  <Address>
                    {this.props.data.prismicContact.data.main_studio_text}
                  </Address>
                </Col>

                <Col col={12} md={8}>
                  <GoogleMap />
                </Col>
              </Row>
            </ContentContainer>
          </Col>
        </Row>
      </>
    );
  }
}

export const query = graphql`
  query Contact {
    prismicContact {
      data {
        title {
          text
        }
        text {
          html
          text
        }
        get_in_touch_text {
          html
        }
        social_media_text {
          html
        }
        mailchimp_signup_text
        main_studio_text
      }
    }
  }
`;

export default Contact;
