import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import styled from 'styled-components';

const ContactForm = styled.div`
  height: auto;

  & p {
    margin: 0;
    padding: 18px;
    width: 100%;

    border: 2px solid #d27dff;
    background-color: #fff;

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
  }
  & p:nth-child(2) {
    border-bottom: 0;
  }
  & p:nth-child(3) {
    border-bottom: 0;
  }
  & p:nth-child(5) {
    padding: 0;
    margin-top: 17px;

    border: 0;
    background-color: #dcbeff;
  }
  & label {
    color: #d27dff;
  }
  & input,
  textarea {
    margin-left: 18px;
    width: calc(100% - 65px - 18px);
    border: 0;
    -webkit-appearance: none;
    padding: 0;
    &:focus {
      outline: none;
    }
  }

  & input {
    height: 22px;
    position: relative;
    line-height: 1;
  }
  & button {
    display: flex;
    justify-content: space-between;

    padding: 17px;
    margin: 0;

    width: 100%;

    border: 0;
    -webkit-appearance: none;

    background-color: #000;
    color: #fff;

    cursor: pointer;
  }
`;

const ContactHeader = styled.div`
  background-color: #d27dff;

  color: #fff;
  text-align: center;
  font-size: 20px;

  padding: 17px;
`;

const MailchimpTitle = styled.p`
  color: #d27dff;
  text-align: center;
  font-size: 17px;

  padding: 38px 0 18px 0;
`;

const MailchimpFormContainer = styled(ContactForm)`
  & p:nth-child(1) {
    border-bottom: 0;
  }

  & p:nth-child(3) {
    border: 2px solid #d27dff;
  }

  & p:nth-child(4) {
    padding: 0;
    margin-top: 17px;
    border: 0;
    background-color: #dcbeff;
  }

  & p {
    & input,
    textarea {
      width: calc(100% - 81px - 18px);
    }
  }
`;

const Arrow = styled.span`
  line-height: 14px;
`;

const MailchimpText = styled.div``;

const PermissionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & p {
    flex-direction: column;
    border-top: 0;
    border-bottom: 2px solid #d27dff !important;
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 20px;
    width: 20px !important;
    z-index: 100;
    margin: 0;
    left: 55px;
  }

  & input:checked ~ span:first-of-type {
    &:after {
      content: '☑';
      color: #000;
    }
  }

  & span {
    position: relative;
  }
`;

const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 20px;
  height: 20px;
  width: 20px;

  cursor: pointer;

  &:after {
    content: '☒';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
  }
`;

class MailchimpForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      FNAME: null,
      LNAME: null,
      EMAIL: null,
      message: 'SIGN UP',
      GDPR: false,
    };
  }

  toggleFilterValue = e => {
    this.setState(prevState => ({
      GDPR: !prevState.GDPR,
    }));
  };

  handleChangeMailchimp = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    });
  };

  submitMailchimp = e => {
    e.preventDefault();

    addToMailchimp(this.state.EMAIL, {
      FNAME: this.state.FNAME,
      LNAME: this.state.LNAME,
      GDPR: this.state.GDPR,
    })
      .then(({ msg, result }) => {
        if (result !== 'success') {
          throw msg;
        }
        this.setState({
          message: msg,
        });
      })
      .catch(err => {
        this.setState({
          message: 'THIS EMAIL IS ALREADY SUBSCRIBED',
        });
        console.log('err', err);
      });
  };

  render(props) {
    return (
      <React.Fragment>
        <MailchimpTitle>{this.props.signupText}</MailchimpTitle>
        <MailchimpFormContainer>
          <ContactHeader>NEWSLETTER SIGN-UP</ContactHeader>

          <form onSubmit={this.submitMailchimp}>
            <p>
              <label>First Name</label>
              <input
                type="text"
                onChange={this.handleChangeMailchimp}
                placeholder="First Name"
                name="FNAME"
              />
            </p>

            <p>
              <label>Last Name</label>
              <input
                type="text"
                onChange={this.handleChangeMailchimp}
                placeholder="Last Name"
                name="LNAME"
              />
            </p>

            <p>
              <label>Email</label>
              <input
                type="email"
                onChange={this.handleChangeMailchimp}
                placeholder="Email"
                name="EMAIL"
              />
            </p>

            <PermissionContainer>
              <p>
                <label>
                  Please select all the ways you would like to hear from Draw:
                </label>

                <span>
                  Email
                  <input
                    type="checkbox"
                    name="GDPR"
                    onChange={this.toggleFilterValue}
                    checked={this.state.GDPR}
                  />
                  <CheckMark />
                </span>
              </p>
            </PermissionContainer>

            <p>
              <button type="submit" disabled={this.state.GDPR !== true}>
                {this.state.message} <Arrow>→</Arrow>
              </button>
            </p>
          </form>
        </MailchimpFormContainer>

        <MailchimpText>
          <p>
            We use Mailchimp as our marketing platform. By clicking above to
            subscribe, you acknowledge that your information will be transferred
            to Mailchimp for processing.{' '}
            <a
              href="https://mailchimp.com/legal/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about Mailchimp's privacy practices here.
            </a>
          </p>
        </MailchimpText>
      </React.Fragment>
    );
  }
}

export default MailchimpForm;
